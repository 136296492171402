import React from 'react';

const LegalAgent = () => {
  return (
    <div>
      <h1>Legal Assistance</h1>
      <iframe
        src="https://localhost:4006"
        style={{ width: '100%', height: '80vh', border: 'none' }}
        title="Legal Assistance"
      />
    </div>
  );
};

export default LegalAgent;

