import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Legal = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Legal Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          The AI chatbot provided on this website is designed to assist users by providing information and answering questions to the best of its ability. However, the responses generated by the AI chatbot are based on algorithms and data inputs and may not always reflect the most current or accurate information.
        </Typography>
        <Typography variant="body1" paragraph>
          The information provided by the AI chatbot is for informational purposes only and should not be considered as professional advice. Users are encouraged to seek professional guidance or consult with qualified experts for specific issues or concerns.
        </Typography>
        <Typography variant="body1" paragraph>
          By using the AI chatbot, you acknowledge and agree that Question Platform and its affiliates, employees, and agents are not responsible for any errors, omissions, or inaccuracies in the information provided by the chatbot. Question Platform shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of or reliance on the information provided by the AI chatbot.
        </Typography>
        <Typography variant="body1" paragraph>
          Users are responsible for verifying the information obtained from the AI chatbot and for making their own decisions based on that information. Question Platform does not guarantee the completeness, reliability, or suitability of the information provided by the chatbot.
        </Typography>
        <Typography variant="body1" paragraph>
          This disclaimer applies to users worldwide. Regardless of your location, by using the AI chatbot, you agree to the terms outlined in this disclaimer.
        </Typography>
        <Typography variant="body1" paragraph>
          Question Platform reserves the right to modify this legal disclaimer at any time. Any changes will be effective immediately upon posting on this website. Your continued use of the AI chatbot after any changes constitutes your acceptance of the new terms.
        </Typography>
        <Typography variant="body1" paragraph>
          This disclaimer shall be governed by and construed in accordance with the laws of Belgium. Any disputes arising from this disclaimer shall be subject to the exclusive jurisdiction of the courts located in Belgium.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Legal;

