import React from 'react';

const FinanceAgent = () => {
  return (
    <div>
      <h1>Finance Assistance</h1>
      <iframe
        src="https://localhost:4010"
        style={{ width: '100%', height: '80vh', border: 'none' }}
        title="Finace Assistance"
      />
    </div>
  );
};

export default FinanceAgent;

