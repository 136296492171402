import React from 'react';
import './ChatbotPlaceholder.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="chatbot-placeholder" onClick={() => window.location.href = 'https://ask-doctor.online/'}>
        <div className="chatbot-title">Medical Assistance</div>
        <img src="/medical.png" alt="Medical Assistance" />
      </div>
      <div className="chatbot-placeholder" onClick={() => window.location.href = 'https://ask-legal.net/'}>
        <div className="chatbot-title">Legal Assistance</div>
        <img src="/legal.png" alt="Legal Assistance" />
      </div>
      <div className="chatbot-placeholder" onClick={() => window.location.href = 'https://ask-payroll.com/'}>
        <div className="chatbot-title">Payroll Assistance</div>
        <img src="/payroll.png" alt="Payroll Assistance" />
      </div>
      <div className="chatbot-placeholder" onClick={() => window.location.href = 'https://ask-finance.online/'}>
        <div className="chatbot-title">Finance Assistance</div>
        <img src="/medical.png" alt="Finance Assistance" />
      </div>
      <div className="chatbot-placeholder" onClick={() => window.location.href = 'https://ask-marketing.online/'}>
        <div className="chatbot-title">Marketing Assistance</div>
        <img src="/medical.png" alt="Marketing Assistance" />
      </div>
    </div>
  );
};

export default LandingPage;
