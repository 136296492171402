import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Select, MenuItem, IconButton, Menu, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Import i18n

const Header = ({ language, setLanguage }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
        </Box>
        <Typography variant="h6" style={{ color: 'white', flexGrow: 1, textAlign: 'center' }}>
          {t('title')}
        </Typography>
        <Select
          value={language}
          onChange={(e) => {
            setLanguage(e.target.value);
            i18n.changeLanguage(e.target.value); // Use i18n to change language
          }}
          sx={{ color: 'white', '& .MuiSelect-icon': { color: 'white' } }}
        >
          <MenuItem value="nl">Dutch</MenuItem>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="de">German</MenuItem>
          <MenuItem value="fr">French</MenuItem>
        </Select>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} component={Link} to="/">{t('home')}</MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/about-us">{t('aboutUs')}</MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/contact">{t('contact')}</MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/legal">{t('legal')}</MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/terms-of-use">{t('termsOfUse')}</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

