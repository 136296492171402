import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const TermsOfUse = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1. Acceptance of Terms</strong>
          <br />
          By accessing or using the services provided by Question Platform, you agree to comply with and be bound by these Terms of Use. If you do not agree with any part of these terms, you must not use our services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2. Use of Services</strong>
          <br />
          Question Platform provides an AI chatbot designed to assist users by providing information and answering questions. You agree to use the services only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use of the services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3. User Responsibilities</strong>
          <br />
          You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify Question Platform immediately of any unauthorized use of your account or any other breach of security.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4. No Warranty</strong>
          <br />
          The services provided by Question Platform are on an "as is" and "as available" basis. Question Platform makes no representations or warranties of any kind, express or implied, regarding the operation of the services or the information, content, materials, or products included in the services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5. Limitation of Liability</strong>
          <br />
          To the fullest extent permitted by law, Question Platform shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of or reliance on the services, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6. Modifications to Terms</strong>
          <br />
          Question Platform reserves the right to modify these Terms of Use at any time. Any changes will be effective immediately upon posting on this website. Your continued use of the services after any changes constitutes your acceptance of the new terms.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7. Governing Law</strong>
          <br />
          These Terms of Use shall be governed by and construed in accordance with the laws of Belgium. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts located in Belgium.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8. Contact Information</strong>
          <br />
          For any questions regarding these Terms of Use, please contact us at <a href="mailto:contact@questionplatform.com">contact@questionplatform.com</a>.
        </Typography>
      </Paper>
    </Container>
  );
};

export default TermsOfUse;

