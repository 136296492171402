import React from 'react';

const MarketingAgent = () => {
  return (
    <div>
      <h1>Marketing Assistance</h1>
      <iframe
        src="https://localhost:4009"
        style={{ width: '100%', height: '80vh', border: 'none' }}
        title="Marketing Assistance"
      />
    </div>
  );
};

export default MarketingAgent;

