import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      title: "AI Chatbot Platform",
      home: "Home",
      aboutUs: "About Us",
      contact: "Contact",
      legal: "Legal",
      termsOfUse: "Terms of Use",
      language: "Language",
    },
  },
  nl: {
    translation: {
      title: "AI Chatbot Platform",
      home: "Startpagina",
      aboutUs: "Over ons",
      contact: "Contact",
      legal: "Juridisch",
      termsOfUse: "Gebruiksvoorwaarden",
      language: "Taal",
    },
  },
  de: {
    translation: {
      title: "AI Chatbot Plattform",
      home: "Startseite",
      aboutUs: "Über uns",
      contact: "Kontakt",
      legal: "Rechtliches",
      termsOfUse: "Nutzungsbedingungen",
      language: "Sprache",
    },
  },
  fr: {
    translation: {
      title: "Plateforme de Chatbot IA",
      home: "Accueil",
      aboutUs: "À propos de nous",
      contact: "Contact",
      legal: "Légal",
      termsOfUse: "Conditions d'utilisation",
      language: "Langue",
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

