import React from 'react';

const PayrollAgent = () => {
  return (
    <div>
      <h1>Payroll Assistance</h1>
      <iframe
        src="https://localhost:4007"
        style={{ width: '100%', height: '80vh', border: 'none' }}
        title="Payroll Assistance"
      />
    </div>
  );
};

export default PayrollAgent;

